import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import './style.scss'
import { URL_FB, URL_TELE_GROUP, URL_X, URL_YT } from "../contans";
import { ChangeEvent } from "react";
import { Noti } from '../Noti/toast';

const Footer = () => {
    const [value, setValue]: any = useState('')
    const location = useLocation()
    let pathUrl = location?.pathname.split("/")
    let pathComing = pathUrl[1] || ''
    const naigation = useNavigate()

    function goToTele() {
        window.open(URL_TELE_GROUP)
    }

    function goToFB() {
        window.open(URL_FB)
    }

    function goToX() {
        window.open(URL_X)
    }

    function goToYT() {
        window.open(URL_YT)
    }
    function gotoPolicy() {
        naigation("/PrivacyPolicy")
    }
    function gotoTerms() {
        naigation("/Term")
    }

    function validateEmail(email: any) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    function subEmail() {
        let check = validateEmail(value)
        if (check) {
            Noti('success', 'Subscribe successfully', 2000)
        } else {
            Noti('error', 'Subscribe Error', 2000)
        }
    }

    function handleChanInput(e: any) {
        setValue(e?.target?.value)
    }

    return (
        <>
            {pathComing === 'coming-soon' ? (
                <>
                    {/* <footer className="footer new">
                        <div className="container coming-f">
                            <div className="wrap-footer">
                                <div className="footer-left">
                                    <div className="title-cm">
                                        Join Our Community
                                    </div>
                                    <div className="list-social">
                                        <div className="item" onClick={goToTele}>
                                            <img src="./images/f-1.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToX}>
                                            <img src="./images/f-2.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToFB}>
                                            <img src="./images/f-3.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToYT}>
                                            <img src="./images/f-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </footer> */}
                </>
            ) : (
                <>
                    <footer className="footer">
                        <div className="container">
                            <div className="wrap-footer">
                                <div className="footer-left">
                                    <div className="box-img">
                                        <img src="./images/logo-ft.png" alt="" />
                                    </div>
                                    <div className="list-social">
                                        <div className="item" onClick={goToTele}>
                                            <img src="./images/f-1.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToX}>
                                            <img src="./images/f-2.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToFB}>
                                            <img src="./images/f-3.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToYT}>
                                            <img src="./images/f-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-right">
                                    <div className="title">
                                        Stay updated with the latest from Mey Network
                                    </div>
                                    <div className="row-input">
                                        <input onChange={(e) => handleChanInput(e)} value={value} type="email" placeholder='Enter your email' />
                                        <button className="btn-subscribe" onClick={subEmail}>
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="copy-right">
                                <div className="left">
                                    MeyNetwork, All rights reserved.
                                </div>
                                <div className="right">
                                    <ul className="list-menu">
                                        {/* <li>
                                            <a>
                                                About Us
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                Contact Us
                                            </a>
                                        </li> */}
                                        <li onClick={gotoPolicy}>
                                            <a>
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li onClick={gotoTerms}>
                                            <a>
                                                Term of Conditions
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="img-ft">
                            <img src="/images/wave1.png" alt="" />
                        </div>
                    </footer>
                    {/* <footer className="footer cus">
                        <div className="container">
                            <div className="wrap-footer">
                                <div className="footer-left">
                                    <div className="box-img">
                                        <img src="./images/logo-ft.png" alt="" />
                                    </div>
                                    <div className="list-social">
                                        <div className="item" onClick={goToTele}>
                                            <img src="./images/f-1.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToX}>
                                            <img src="./images/f-2.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToFB}>
                                            <img src="./images/f-3.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToYT}>
                                            <img src="./images/f-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="copy-right">
                                <div className="left">
                                    Mey Network, All rights reserved.
                                </div>

                            </div>
                        </div>
                        <div className="img-ft">
                            <img src="/images/wave1.png" alt="" />
                        </div>
                    </footer> */}
                </>
            )}
        </>



    )
}

export default Footer;