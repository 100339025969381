import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import './App.css';
import HomePage from './pages/home-page';
import Header from './components/common/header';
import Footer from './components/common/footer';
import Main from './components/main';
import ComingSoon from './components/comingsoon';
import Policy from './pages/polycy';
import Terms from './pages/terms';

function App() {
    return (
        <div className="App">
            {/* <Header />
            <HomePage />
            <Footer /> */}
            <Main>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/PrivacyPolicy" element={<Policy />} />
                    <Route path="/Term" element={<Terms />} />

                    <Route path="/coming-soon" element={<ComingSoon />} />

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Main>

        </div>
    );
}

export default App;
