import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './style.scss'
import { isMobile } from "react-device-detect";

const Header = () => {
    const naigation = useNavigate()

    const handleLaunchApp = () => {
        naigation("/coming-soon")
    }
    const handleHome = () => {
        naigation("/")
    }

    const location = useLocation()
    let pathUrl = location?.pathname.split("/")
    let pathComing = pathUrl[1] || ''

    console.log('pathComing', pathComing)
    function gotoMenu() {
        naigation(`/`)
    }

    return (
        <>
            <div className="header-landing">
                <div className="container">
                    <div className="content-header-land">
                        <div className="header-land-left">
                            <div className="logo-header-land">
                                {isMobile ? (
                                    <div className="img-logo-land-mo" onClick={handleHome}>
                                        <img src="./images/main-logo-mo.png" alt="" />
                                    </div>
                                ) : (
                                    <div className="img-logo-land" onClick={handleHome}>
                                        <img src="./images/main-logo.png" alt="" />
                                    </div>
                                )}

                            </div>
                            {/* <div className="item-header-land">
                                <a href="#meyfi">Why meyFi</a>
                            </div> */}
                            {pathComing === 'coming-soon' ? ('') : (
                                <>
                                    <div className="item-header-land">
                                        <a onClick={gotoMenu} href="#feature">Features</a>
                                    </div>
                                    <div className="item-header-land">
                                        <a onClick={gotoMenu} href="#work">How it work</a>
                                    </div>
                                    <div className="item-header-land">
                                        <a onClick={gotoMenu} href="#about">About</a>
                                    </div>
                                </>
                            )}

                            {/* <div className="item-header-land">
                                <a href="#">Docs</a>
                            </div> */}
                        </div>
                        <div className="header-land-right">
                            <button className="btn-launch-app" onClick={handleLaunchApp}>Launch App</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;